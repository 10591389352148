@use "@angular/material" as mat;
@include mat.all-component-typographies();
@include mat.core();
@import 'bootstrap/scss/bootstrap';
@import "assets/scss/global.scss";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@angular/material/theming";

$primary-palette: mat.define-palette(mat.$indigo-palette);
$accent-palette: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$warn-palette: mat.define-palette(mat.$red-palette);
$light-theme: mat.define-light-theme((color: (primary: $primary-palette,
        accent: $accent-palette,
        warn: $warn-palette,
      ),
    ));

$dark-theme: mat.define-dark-theme((color: (primary: $primary-palette,
        accent: $accent-palette,
        warn: $warn-palette,
      ),
    ));

.mat-select {
  font-size: 13px;
  font-family: 'poppins' !important;
  font-weight: 400px;
}

body.light,
body .light {
  @include mat.all-component-colors($light-theme);
}

body.dark,
body .dark {
  @include mat.all-component-colors($dark-theme);
}

:root {
  --mdc-theme-secondary: #2DCC70;
}

body {
  * {

    &:focus,
    &.focus,
    &:active,
    &.active {
      outline: transparent none 0 !important;
      box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
      -webkit-box-shadow: none !important;
    }
  }
}

$font-family: '"Poppins", sans-serif';

html,
body {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  background-color: #f5f6f8 !important;
}

input,
select,
textarea {
  font-family: 'Poppins' !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: inherit;
}

input::-webkit-input-placeholder {
  font-size: 13px !important;
  font-weight: 500;
  color: rgba(130, 134, 154, 0.5);
}

input::-moz-placeholder {
  font-size: 13px !important;
  font-weight: 500;
  color: rgba(130, 134, 154, 0.5);
}

input::-ms-input-placeholder {
  font-size: 13px !important;
  font-weight: 500;
  color: rgba(130, 134, 154, 0.5);
}

.white-bg {
  background-color: #fff;
}

//tooltip
.tooltip.fade .tooltip-inner {
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 8px;
  padding: 24px;
  font-size: 12px;
  color: #041D41;
  opacity: 1;
  font-family: "Poppins", sans-serif !important;
}

.tooltip.show {
  position: absolute;
  z-index: 999999 !important;
  opacity: 1 !important;
}

.tooltipForNode {
  position: absolute;
  background-color: white;
  max-width: 200px;
  height: auto;
  padding: 10px;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
  pointer-events: none;
  font-size: 14px;
  z-index: 10;
}

//circle across image 
.circled-img-container {
  border-radius: 50%;
}

// add button used in all module
.btn-outline1 {
  color: #FF455B;
}

.btn-primary1 {
  color: #495057;
  background-color: #fff;
  border-color: #fff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}

// default button 
.red-btn-solid {
  background: #FF455B;
  border-radius: 5px;
  padding: 8px 45px;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.list-group-item {
  position: relative;
  display: block;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 0px 5px 10px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 94.7%;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}

.panel-warning {
  border-color: #ffcccc;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.width50 {
  width: 44.2%;
  min-height: 30px;
  height: auto;
  float: left;
  margin: 15px 15px;
  border: 1px solid #e4e4e4;
  padding: 5px;
}

.panel-heading {
  width: 95%;
  min-height: 30px;
  float: left;
  background-color: #ffcccc;
  color: #fff;
  padding: 15px;
}

.vender_div {
  width: 100%;
  height: 350px;
  float: left;
  max-height: 530px;
  overflow: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
  border: 1px solid #e4e4e4;
}

.panel-body {
  clear: both !important;
}

.filter-option>.mat-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #CBCED4 !important;
}

.mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #43DF94 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #43DF94 !important;
}

.sm-toggle {
  .mat-slide-toggle-bar {
    width: 30px !important;
    height: 10px !important;

    .mat-slide-toggle-thumb-container {
      width: 20px !important;
      height: 20px !important;

      .mat-slide-toggle-thumb {
        height: 15px !important;
        width: 15px !important;
      }
    }
  }
}

//Date picker styles
.mat-calendar-controls {
  margin: 0% calc(20% / 7 - 16px) !important;
}

.mat-datepicker-content .mat-calendar {
  height: 300px !important;
}

.mat-calendar-header {
  padding: 0px 8px 0 8px !important;
}

.mat-calendar-body-cell-content {
  width: 60% !important;
  height: 60% !important;
}

.mat-calendar-table-header th {
  padding: 0 0 2px 0 !important;
}

small {
  font-size: 10px;
}

.start_end_time {
  width: 19px !important;
  height: 19px !important;
  margin-left: -11px !important;
}

.emp_details {
  line-height: 30px;
}

.v_lable {
  width: 100%;
  min-height: 20px;
  height: auto;
  float: left;
  color: #000;
  margin: 10px 0px 0px 0px;
  font-size: 14px;
}

.v_detail {
  width: 100%;
  min-height: 20px;
  height: auto;
  float: left;
  color: #4CB446;
  font-size: 22px;
  margin-bottom: 15px;
}

.v_detail span {
  font-size: 12px;
}

.player_div {
  width: 100%;
  min-height: 100px;
  height: auto;
  float: left;
  border: 1px solid #e4e4e4;
  margin-top: -10px;
}

.play_btn {
  width: 100%;
  min-height: 20px;
  height: auto;
  float: left;
  text-align: center;
  color: #FF4D43;
  font-size: 50px;
  margin-top: 20px;
  cursor: pointer;
}

.play_btn:hover {
  color: #3399ff;
}

.player_total {
  width: 100%;
  height: 10px;
  float: left;
  background: #e4e4e4;
  margin-top: 50px;
}

.player_process {
  min-width: 0%;
  width: auto;
  height: 10px;
  float: left;
  background: #FF4D43;
  transition: all .5s ease;
}

.player_time {
  width: 100%;
  min-width: 20px;
  height: auto;
  float: left;
  color: #777777;
  font-size: 14px;
  text-align: right;
}

hr {
  margin: 5px 0px !important;
}

.player_speed {
  width: 80%;
  height: 50px;
  float: left;
  border: 1px solid #e4e4e4;
  margin-top: 30px;
}

.player_speed_m {
  width: 50%;
  height: 50px;
  float: left;
  color: #3399ff;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 50px;
}

.player_speed_p {
  width: 50%;
  height: 50px;
  float: left;
  color: #00B259;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 50px;
}

.player_speed_p:hover,
.player_speed_m:hover {
  color: #000;
}

.player_speed_limit {
  width: 20%;
  height: 30px;
  float: left;
  color: #999999;
  font-size: 12px;
  margin-top: 65px;
  text-align: center;
}

.lt_r_header {
  width: 100%;
  min-height: 20px;
  height: auto;
  float: left;
  padding: 15px 0px 0px 0px;
  border-bottom: 1px solid #e4e4e4;
}

:focus {
  outline: none;
}

.lt_r_search {
  width: 100%;
  height: 50px;
  float: left;
  border: 1px solid #e4e4e4;
  margin-top: 5px;
  border-radius: 5px;
  text-indent: 10px;
}

.searchicon {
  width: 50px;
  height: 48px;
  float: right;
  font-size: 22px;
  color: #999;
  background: #fff;
  text-align: center;
  margin-top: -49px;
  line-height: 50px;
  margin-right: 1px;
  cursor: pointer;
  position: relative;
  z-index: 9999;
}

.lt_r_reply_item {
  width: 98%;
  min-height: 30px;
  height: auto;
  margin: 0px auto;
  background: #3399ff;
  color: #fff;
  font-size: 14px;
  padding: 15px 10px;
  margin-top: 5px;
  cursor: pointer;
}

.lt_r_reply_item:hover {
  background: rgb(1, 124, 73);
}

.lt_r_detail_total {
  width: 100%;
  min-height: 10px;
  height: auto;
  float: left;
  margin-bottom: 10px;
}

.lt_r_detail_header {
  width: 100%;
  min-height: 20px;
  height: auto;
  float: left;
  margin-top: 10px;
}

.t_left_align {
  text-align: left;
}

.t_right_align {
  text-align: right;
}

.lt_r_time_status_div {
  width: 100%;
  min-height: 20px;
  height: auto;
  float: left;
  color: #000;
  font-weight: bold;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.deiver_emp_detail_total {
  width: 100%;
  height: 137px;
  float: left;
  border: 1px solid #e4e4e4;
  margin-bottom: 30px;
}

.driver_detail_div {
  width: 25%;
  min-height: 136px;
  height: auto;
  float: left;
  border-right: 1px solid #e4e4e4;
}

.driver_detail_heading {
  width: 100%;
  min-height: 20px;
  height: auto;
  float: left;
  padding: 15px 0px;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  border-bottom: 1px solid #e4e4e4;
  text-indent: 10px;
}

.driver_item_icon {
  width: 100%;
  min-height: 10px;
  height: auto;
  float: left;
  text-align: center;
  margin-top: 10px;
  margin-left: 8px;
}

.driver_name {
  width: 100%;
  min-height: 10px;
  height: auto;
  float: left;
  font-size: 14px;
  margin-top: 15px;
  color: #000;
  line-height: 15px;
}

/*===== Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;

}

.timeline-article {
  width: 53%;
  height: 35px;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}

.timeline-article .content-left-container,
.timeline-article .content-right-container {
  max-width: 44%;
  width: 100%;
}

.timeline-article .timeline-author {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #242424;
  text-align: right;
}

.timeline-article .content-left,
.timeline-article .content-right {
  position: relative;
  width: auto;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .03);
  padding: 27px 25px;
}

.timeline-article p {
  margin: 0 0 0 60px;
  padding: 0;
  font-weight: 400;
  color: #242424;
  font-size: 14px;
  line-height: 24px;
  position: relative;
}

.timeline-article p span.article-number {
  position: absolute;
  font-weight: 300;
  font-size: 44px;
  top: 10px;
  left: -60px;
  color: #00b0bd;
}

.timeline-article .content-left-container {
  float: left;
}

.timeline-article .content-right-container {
  float: right;
}

.timeline-article .content-left:before,
.timeline-article .content-right:before {
  position: absolute;
  top: 20px;
  font-size: 23px;
  font-family: "FontAwesome";
  color: #fff;
}

.timeline-article .content-left:before {
  content: "\f0da";
  right: -8px;
}

.timeline-article .content-right:before {
  content: "\f0d9";
  left: -8px;
}

.timeline-article .meta-date {
  position: absolute;
  top: 0;
  left: 50%;
  width: 13px;
  height: 13px;
  margin-left: -8px;
  color: #fff;
  border-radius: 100%;
  background: #e4e4e4;
  margin-top: 8px;
}

.timeline-article .meta-date .date,
.timeline-article .meta-date .month {
  display: block;
  text-align: center;
  font-weight: 900;
}

.timeline-article .meta-date .date {
  font-size: 30px;
  line-height: 40px;
}

.timeline-article .meta-date .month {
  font-size: 18px;
  line-height: 10px;
}

/*===== // Vertical Timeline =====*/

/*===== Resonsive Vertical Timeline =====*/
@media only screen and (max-width: 830px) {

  #conference-timeline .timeline-start,
  #conference-timeline .timeline-end {
    margin: 0;
  }

  #conference-timeline .conference-center-line {
    margin-left: 0;
    left: 50px;
  }

  .timeline-article .meta-date {
    margin-left: 0;
    left: 20px;
  }

  .timeline-article .content-left-container,
  .timeline-article .content-right-container {
    max-width: 100%;
    width: auto;
    float: none;
    margin-left: 110px;
    min-height: 53px;
  }

  .timeline-article .content-left-container {
    margin-bottom: 20px;
  }

  .timeline-article .content-left,
  .timeline-article .content-right {
    padding: 10px 25px;
    min-height: 65px;
  }

  .timeline-article .content-left:before {
    content: "\f0d9";
    right: auto;
    left: -8px;
  }

  .timeline-article .content-right:before {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .timeline-article p {
    margin: 0;
  }

  .timeline-article p span.article-number {
    display: none;
  }
}

.matCheckBox .mat-checkbox-inner-container-no-side-margin {
  margin-top: 3px !important;
}

.exp-header {
  background-color: #f1f1f1 !important;
}

.exp-header.mat-expanded:focus,
.exp-header.mat-expanded:hover {
  background-color: #f1f1f1 !important;
}


.summary thead th {
  background: #9a9a9a;
  color: #fff;
  height: 40px;
  text-align: center;
  box-shadow: inset 0px 0px 10px 0px #ffffff;
}

.summary thead th:first-child {
  box-shadow: none;
  background: #9a9a9a;
  text-align: center;
  font-size: medium !important;
  border-bottom-left-radius: 0.5px;
  border-top-left-radius: 0.5px;
}

.summary thead th:last-child {
  border-bottom-right-radius: 0.5px;
  border-top-right-radius: 0.5px;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-45px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
}

.animated {
  animation-delay: 3s;
  animation-duration: 2.5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.options-loader {
  width: 20px !important;
  height: 15px !important;

  svg {
    width: 20px !important;
    height: 15px !important;
  }
}

.options-loader {
  width: 20px !important;
  height: 15px !important;

  svg {
    width: 20px !important;
    height: 15px !important;
  }
}

.mat-toolbar-single-row {
  height: 53px !important;
}

.dropdown-list li {
  width: 100%;
}

.dropdown-list .filter-textbox input {
  padding: 0 0 0 10px;
}

.selected-item {
  max-width: 55% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-item a {
  padding-top: 2px;
  padding-left: 2px;
  min-width: 10px;
  color: #fff;
  min-height: 20px;
  float: right;
  line-height: 15px;
  padding: 5px;
  margin-top: -25px;
  margin-right: -5px;
  position: relative;
}

.booking {
  .multiselect-dropdown .dropdown-btn {
    margin-top: 5px;
    height: 50px;
    line-height: 2 !important;
  }
}

.subscription-report-search-container {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: .8em 0 0.8em 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0em 0 !important;
  }
}

.form-container {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1.0em 0 1.0em 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0em 0 !important;
  }
}

.page-label {
  .mat-raised-button {
    min-width: 50px;
  }
}

*:focus {
  outline: none;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}


.mat-icon-button.mat-accent {
  margin-top: -15px;
}

.error-card {
  color: #fff !important;
  height: 5px;
  font-weight: 600;
  line-height: 5px;
  margin: 5px 0px 5px 0px;
  background: #7FB3D5;
}

.paginator .mat-paginator-container {
  min-height: 45px !important;
}

.paginator .mat-paginator-page-size-select {
  margin: 0px 4px 0 4px !important;
}

.ev-billing-table {
  th {
    min-width: 100px;
  }
}

.driver_detail_div table.table>tbody>tr>td {
  padding: 8px 0px !important;
}

.mainlogo {
  width: 100%;
  min-height: 30px;
  height: auto;
  float: left;
  text-align: center;
  color: #fff;
  font-weight: 600;
  padding: 10px 0px;
}

.vehicle-driver {
  .mat-drawer-content {
    overflow: visible !important;
  }
}

.mat-bottom-sheet-container-large {
  max-width: calc(100vw - 150px) !important;
}

th.tdw-bottom-line-light.tdw-padding-13-6.tdw-font-14.tdw-font-open-sans.tdw-font-normal.mat-color-text--grey-800 {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

i.close-icon {
  position: absolute;
  top: -10px;
  right: -10px;
}

app-ota-otd-report {
  .mat-expansion-panel-body {
    padding: 8px 16px 16px !important;
  }
}

app-trip-list {
  .mat-expansion-panel-body {
    padding: 0 0px 1px !important;
  }
}

app-dashboard {
  .mat-expansion-panel-header {
    padding: 0 8px !important;
  }
}

mat-card.card-trip-detail {
  padding: 10px 0 30px 0;
}

#zonemap .leaflet-right {
  display: none;
}

#apl-map .leaflet-routing-container-hide {
  display: none;
}

.total_routing {
  .mat-form-field {
    width: 100% !important;
  }
}

.booking_tbl {
  .scrollable-content {
    max-height: 120vh !important;
    height: auto !important;
  }
}

.emp-name-fab {
  float: left;

  .mat-button-wrapper {
    line-height: 5px !important;
  }
}

.pagination-info {
  padding-top: 9px;
  font-style: italic;
}

.trip-panel {
  .mat-expansion-panel-body {
    padding: 0px;
  }
}

.mapClass path {
  stroke: #ff0800;
  stroke-width: 2px;
}

.mapClass path:hover {
  fill: red;
  stroke-width: 2px;
}

.name_display {
  color: #e23028;
  word-wrap: break-word;
  font-weight: 1000;
  font-size: 15px;
  margin-left: 180px;
}

.map_close {
  margin-right: 5px;
  width: 50px;
  height: 25px;
  float: right;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  text-align: center;
  position: relative;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
  z-index: 999;
}

// no data found font for ng-multiselect
.multiselect-dropdown {
  .dropdown-list {
    .no-filtered-data {
      h5 {
        font-size: 13px !important;
        font-weight: 400 !important;
        font-family: 'Poppins' !important;
        white-space: nowrap !important;
        color: #212529 !important;
      }

    }

    .no-data {
      h5 {
        font-size: 13px !important;
        font-weight: 400 !important;
        font-family: 'Poppins' !important;
        white-space: nowrap !important;
        color: #212529 !important;
      }
    }
  }
}

// multi select check box text
.mdc-list-item__primary-text {
  color: #041D41 !important;
}

// tab link for company
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #2DCC70);
}

// select data for multiselect
.mat-mdc-select-min-line {
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: 'Poppins' !important;
  color: #212529 !important;
}

//slick slider custom
.slick-slider .slick-prev,
.slick-slider .slick-next {
  height: 0px;
  width: 70px;
  z-index: 11111;
  top: 40px;
  background: grey !important;
  z-index: 0;
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  color: darkgrey;
}

.slick-slider .slick-slide {
  margin: 0 5px;
}

.slick-slider .slick-list {
  margin: 0 -5px;
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}