//
// _helper.scss
//

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}
.font-size-36 {
  font-size: 36px !important;
}
.font-size-38 {
  font-size: 38px !important;
}

// Font weight help class

.fw-medium {
  font-weight: $fw-medium;
}
◘

.font-weight-meduim {
  font-weight: $font-weight-medium!important;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold!important;
}

.font-weight-bold {
  font-weight: $font-weight-bolder!important;
}

.login-heading {
  color: $login-main-head-color;
}



//colors 
.text-muted-light {
  color: rgba(40, 44, 64, 0.6)!important;
}
.text-muted-light-gray {
  color: rgba(130, 134, 154, 0.6)!important;
}
.text-medium-gray {
  color: #A7A7A7;
}
.white-text-color {
  color: #ffffff;
}
// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid $gray-500;
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
  transition: all 0.4s;

  &:hover {
    color: $gray-600;
    background-color: $gray-200;
  }
}


.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

// media

.media {
  display: flex;
  // align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
}

.media-body {
  flex: 1;
}

// alert

.alert-dismissible {
  .btn-close {
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  }
}

.custom-alert {
  .close {
    background: transparent;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .5;
    width: 1em;
    height: 1em;
    z-index: 2;

    span {
      display: none;
    }
  }
}


//spacing
.btm-0 {
  bottom: 0;
}
.rht-10 {
  right: 10px;
}

//button
.action-primary-button{
color: #ffffff;
font-weight: 600;
font-size: 13px;
 background: #FF455B;
  border-radius: 5px;
  padding: 8px 20px;
  border: none;
}
.v-align {
  vertical-align: middle;
}

//border
.b-0 {
  border: none!important;
}