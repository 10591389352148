@media (min-width: 767px) and (max-width: 1200px){ 
    .login-content-wrapper{
        .login-text-container{
            width: 50vw!important;
            // height: auto!important;
        } 
    }
}

@media (max-width: 766px){ 
    .login-content-wrapper{
        .login-text-container{
             
            height: auto!important;
        } 
    }
}