//secondary nav tabs
.g-nav-tabs-custom.nav-tabs-custom .nav-item .nav-link::after {
    background-color: #2DCC70;
}

.g-nav-tabs-custom.nav-tabs-custom .nav-item .nav-link.active {
    color: #041D41;
}

.g-nav-tabs-custom {
    li a {
        padding: 0px 26px 14px 26px;
        font-size: 14px;
    }
}

.display {
    display: none;
}

//mat field
.c-mat-field {
    font-family: "Poppins", sans-serif !important;

    .mat-form-field-wrapper {
        padding-bottom: 0 !important;

        .mat-input-element:disabled {
            color: #041D41 !important;
            font-weight: 500;
            font-size: 15px;
        }
    }

    .mat-form-field-infix {
        width: 200px !important;
    }

}

//back
.back-light {
    color: #A1ABBC;
    font-weight: 500;
    font-size: 14px;
}

btn btn-primary1 {
    font-size: 14px;
}

.form-error-state {
    color: #ff2020;
}

.btn-blue {
    color: #2ebcf0;
    background-color: #e5f2ff;
    border-color: #fff;
}

.font-size-14 {
    font-size: 14px !important;
}

// for download template
.textcolor {
    color: #6B727E;
}

.iconProperty {
    color: #6B727E;
    position: relative;
    top: 4px;
    cursor: pointer;
}

// for search bar
.icon {
    padding-left: 25px !important;
    background: url("/assets/images/icons/Research.png") no-repeat left !important;
    background-size: 20px !important;
}

.s-input {
    border: none !important;
    background-color: #F5F7FE !important;
    padding-right: 0 !important;
}

.actionDiv {
    display: flex;
    position: relative;
    top: -5px;
}

// for add button div
.add-div {
    padding-bottom: 1px;
    border-bottom: 1px solid #dbdee0;
    justify-content: flex-end;
}

// for edit button div
.edit-div {
    padding-bottom: 1px;
    border-bottom: 1px solid #dbdee0;
}

// for mandatory filed
.mandatory {
    color: red;
    font-size: 15px;
    position: relative;
    top: 0px;
    right: -2px;
}

//multiselect dropdown
.c-multiselect {
    .multiselect-dropdown {
        padding: 6px !important;

        .dropdown-btn {
            border: none !important;
            padding: 0px !important;

            .dropdown-multiselect__caret {
                display: none !important;
            }

            .selected-item-container {
                position: relative !important;
                top: -3px !important;

                .selected-item {
                    background-color: #F7F7F7 !important;
                    color: #9FA9B1 !important;
                    border-radius: 3px !important;
                    padding: 0px 9px !important;
                    border: none !important;
                    max-width: 130px !important;
                    margin: 4px 0 0 2px !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;


                    a {
                        color: #9FA9B1 !important;
                    }
                }
            }
        }
    }
}

//for add & edit btn while download btn also required
.addEditBtn {
    font-size: 14px !important;
    font-weight: 500 !important;
}

//For Add btn
.plus-btn-property {
    position: absolute;
    top: 10px;
    right: 10px;
    padding-bottom: 1px;
    width: auto;

    .add-btn-outline1 {
        color: #FF455B !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        border: none !important;
    }
}

.red-btn-solid {
    background: #FF455B !important;
    border-radius: 5px !important;
    padding: 7px 45px !important;
    color: #fff !important;
    font-size: 14px !important;

    &:hover {
        color: #fff !important;
    }
}

.red-btn {
    background: #FF455B !important;
    border-radius: 5px !important;
    color: #fff !important;
    font-size: 14px !important;
    width: inherit !important;
}

.iconFont {
    font-size: 18px !important;
}

.font-size-font {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: "poppins" !important;
}

.error-msg {
    font-family: 'poppins' !important;
    color: red;
    font-size: 11px;
    font-weight: 500;
    margin-top: 3px;
}

//For clock icon
.clock-icon {
    background: url("/assets/images/icons/clock.svg") no-repeat right 0.75rem center !important;
}